<template>
  <div>
    <CToaster position="top-right" :autohide="1500">
      <template v-for="(toast, index) in toasts">
        <CToast
            :key="'toast' + toast.text + index"
            :show="true"
            :header="toast.header === '' ? null : toast.header"
        >
          {{ toast.text }}
        </CToast>
      </template>
    </CToaster>
    <CCard>
      <CCardHeader>
        Legal Dökümanlar
      </CCardHeader>
      <CCardBody>
        <CDataTable
            class="mb-0 table-outline"
            hover
            :items="tableItems"
            :fields="tableFields"
            head-color="light"
            :loading="isDataLoading"
        >
          <template #no-items-view>
            <CRow>
              <CCol class="col-12 font-3xl text-center">
                <CIcon name="cil-x"/>
                Veri Bulunamadı
              </CCol>
            </CRow>
          </template>
          <td slot="id" slot-scope="{item}">
            <div>{{ item.id }}</div>
          </td>
          <td slot="title" slot-scope="{item}">
            <div>{{ item.title }}</div>
          </td>
          <td slot="slug" slot-scope="{item}" class="text-center">
            <div>{{ item.slug }}</div>
          </td>
          <td slot="manage" slot-scope="{item}">
            <CCol col="12" class="text-center mb-3">
              <router-link :to="'/legal-dokuman-duzenle/' + item.id" tag="a" role="button" class="btn btn-success">
                <CIcon name="cil-pencil"/>
              </router-link>
            </CCol>
          </td>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import {LegalDocumentAdministration} from "@/services/api-service";

export default {
  name: 'LegalDocuments',
  components: {},
  mounted() {
    this.loadLegalDocuments();
  },
  data() {
    return {
      toasts: [],
      isDataLoading: false,
      tableItems: [],
      tableFields: [
        {key: 'id', label: 'Id'},
        {key: 'title', label: 'Başlık'},
        {key: 'slug', label: 'Slug', _classes: 'text-center'},
        {key: 'manage', label: 'Yönet', _classes: 'text-center'}
      ]
    }
  },
  methods: {
    color(value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
    async loadLegalDocuments() {
      this.isDataLoading = true;
      try {
        const result = await LegalDocumentAdministration.getAllLegalDocumentsForAdministration();
        this.tableItems = result.data.legalDocuments;
      } catch (e) {
        this.toasts.push("Veri Getirilemedi", "Hata");
      }
      this.isDataLoading = false;
    }
  }
}
</script>

