var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CToaster',{attrs:{"position":"top-right","autohide":1500}},[_vm._l((_vm.toasts),function(toast,index){return [_c('CToast',{key:'toast' + toast.text + index,attrs:{"show":true,"header":toast.header === '' ? null : toast.header}},[_vm._v(" "+_vm._s(toast.text)+" ")])]})],2),_c('CCard',[_c('CCardHeader',[_vm._v(" Legal Dökümanlar ")]),_c('CCardBody',[_c('CDataTable',{staticClass:"mb-0 table-outline",attrs:{"hover":"","items":_vm.tableItems,"fields":_vm.tableFields,"head-color":"light","loading":_vm.isDataLoading},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('CRow',[_c('CCol',{staticClass:"col-12 font-3xl text-center"},[_c('CIcon',{attrs:{"name":"cil-x"}}),_vm._v(" Veri Bulunamadı ")],1)],1)]},proxy:true},{key:"id",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('div',[_vm._v(_vm._s(item.id))])])}},{key:"title",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('div',[_vm._v(_vm._s(item.title))])])}},{key:"slug",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('div',[_vm._v(_vm._s(item.slug))])])}},{key:"manage",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('CCol',{staticClass:"text-center mb-3",attrs:{"col":"12"}},[_c('router-link',{staticClass:"btn btn-success",attrs:{"to":'/legal-dokuman-duzenle/' + item.id,"tag":"a","role":"button"}},[_c('CIcon',{attrs:{"name":"cil-pencil"}})],1)],1)],1)}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }